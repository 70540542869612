import styled from '@mui/material/styles/styled'
import { Swiper } from 'swiper/react'

export const StyledControlsWrapper = styled('div', {
  name: 'ProductTileGallery',
  slot: 'ControlsWrapper',
  shouldForwardProp: prop => prop !== 'isDisplayed' && prop !== 'isCompact',
})<{ isDisplayed?: boolean; isCompact?: boolean }>(({ isDisplayed = true, theme, isCompact = true }) => ({
  zIndex: 1,
  display: isDisplayed ? 'flex' : 'none',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `0 ${theme.spacing(12)}`,
  marginBottom: theme.spacing(4),
  paddingBottom: isCompact ? theme.spacing(8) : 0,
  color: theme.palette.text.dark.primary,
  '.icon-container': {
    fontSize: 0,
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(15),
    padding: `0 ${theme.spacing(15)}`,
  },
}))

export const StyledControlsWrapperColorLabel = styled('div', {
  name: 'ProductTileGallery',
  slot: 'ControlsWrapperColorLabel',
})(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: '50%',
  maxWidth: '50%',
  textAlign: 'center',
  textTransform: 'capitalize',
}))

export const StyledSwiper = styled(Swiper, {
  name: 'ProductTileGallery',
  slot: 'Swiper',
  shouldForwardProp: prop => prop !== 'isDisplayed',
})<{ isDisplayed?: boolean }>(({ isDisplayed }) => ({
  width: '100%',
  flex: isDisplayed ? 1 : 0,
  zIndex: 0,

  '.swiper-slide': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
