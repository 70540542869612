import { styled } from '@mui/material/styles'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { StyledAnchor, StyledButtonAsLink } from '../../UI'

import { Z_INDEX_LV1 } from '../../../constants/ui'

// TODO: need to migrate to styled MUI and refactoring ProductTile component

export const StyledColorVariantsButtons = styled('div', {
  name: 'ColorVariants',
  slot: 'Buttons',
})(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}))

export const StyledProductSubWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'ProductSubWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  position: 'relative',
  minHeight: 164,
  aspectRatio: '1',
  cursor: 'pointer',

  '&::before': {
    content: '""',
    paddingTop: '100%',

    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  [theme.breakpoints.up('sm')]: {
    height: 'auto',
  },
}))

export const StyledProductAnchor = styled(StyledAnchor, {
  name: 'ProductAnchor',
  slot: 'AnchorElement',
  shouldForwardProp: prop => prop !== 'isClRelatedTile',
})<{ isClRelatedTile?: boolean }>(({ isClRelatedTile, theme }) => ({
  width: '100%',
  paddingTop: isClRelatedTile ? theme.spacing(8) : 0,
  fontSize: 0,

  '&::after': {
    height: '0 !important',
  },

  [theme.breakpoints.up('xs')]: {
    paddingTop: 0,
  },

  [theme.breakpoints.up('sm')]: {
    paddingTop: isClRelatedTile ? theme.spacing(6) : 'inherit',
  },

  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}))

export const StyledProductDescriptionAnchor = styled(StyledAnchor, {
  name: 'ProductDescriptionAnchor',
  slot: 'AnchorElement',
  shouldForwardProp: prop => prop !== 'isClRelatedTile',
})<{ isClRelatedTile?: boolean }>(({ isClRelatedTile = true, theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(isClRelatedTile ? 8 : 0),
  color: theme.palette.text.primary,

  '&::after': {
    height: '0 !important',
  },

  [theme.breakpoints.up('xs')]: {
    paddingTop: 0,
  },

  [theme.breakpoints.up('sm')]: {
    paddingTop: isClRelatedTile ? theme.spacing(6) : 'inherit',
  },

  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}))

export const StyledSquare = styled('div', {
  name: 'StyledSquare',
  slot: 'Divider',
})(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.background.light.primary,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export const StyledProductTileWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'TileWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: '0.875rem',
  fontWeight: 'normal',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  position: 'relative',
  minHeight: '220px',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem',
  },

  '&.arn-product-tile-ymal': {
    position: 'relative',
    aspectRatio: '1/1',

    '@supports not (aspect-ratio: 1 / 1)': {
      minHeight: 356,
    },
  },
}))

export const StyledProductTileBadgeIcons = styled('div', {
  name: 'ProductTile',
  slot: 'BadgeIcons',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > svg:not(:last-child)': {
    [theme.breakpoints.up('sm')]: {
      marginRight: '0.625rem',
    },
    marginRight: 0,
  },

  svg: {
    color: theme.palette.custom.black,
  },
}))

export const StyledProductTileImageContainer = styled('div', {
  name: 'ProductTile',
  slot: 'ImageContainer',
  shouldForwardProp: prop => prop !== 'isDisplayed' && prop !== 'isClRelatedTile' && prop !== 'isFull',
})<{ isDisplayed?: boolean; isClRelatedTile?: boolean; isFull?: boolean }>(
  ({ isDisplayed = true, isClRelatedTile = true, theme }) => ({
    display: isDisplayed ? 'flex' : 'none',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up('xs')]: {
      paddingTop: 0,
      alignSelf: 'center',
    },

    [theme.breakpoints.up('sm')]: {
      marginTop: isClRelatedTile ? theme.spacing(6) : 0,
    },
  })
)

export const StyledColorVariantsBtn = styled(StyledButtonAsLink, {
  name: 'ColorVariants',
  slot: 'AnchorButton',
})({
  fontSize: 0,

  '&:disabled': {
    opacity: 0.5,
  },
})

export const StyledColorVariantName = styled('div', {
  name: 'ColorVariant',
  slot: 'TextField',
})(({ theme }) => ({
  flex: 1,
  fontSize: '1rem',
  color: theme.palette.text.primary,
  textAlign: 'center',
}))

export const StyledProductTileFooter = styled('div', {
  name: 'ProductTile',
  slot: 'Footer',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  display: 'flex',
  flexDirection: 'row',

  '& > div': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  '& > div:nth-of-type(2)': {
    flex: 0,

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
}))

export const StyledProductTileDescription = styled('div', {
  name: 'ProductTile',
  slot: 'Description',
  shouldForwardProp: prop => prop !== 'isPDP' && prop !== 'hasFavIcon' && prop !== 'isSearchLayer',
})<{ isPDP: boolean | null; hasFavIcon?: boolean; isSearchLayer?: boolean }>(
  ({ isPDP, hasFavIcon, isSearchLayer }) => ({
    alignItems: isPDP || isSearchLayer ? 'center' : 'flex-start',
    whiteSpace: 'nowrap',
    width: hasFavIcon ? '80%' : '100%',
  })
)

export const StyledProductName = styled('div', {
  name: 'ProductTile',
  slot: 'ProductName',
  shouldForwardProp: prop => prop !== 'type' && prop !== 'isSearchLayer',
})<{ type?: string; isSearchLayer?: boolean }>(({ theme, type, isSearchLayer }) => ({
  fontSize: type === 'contact-lenses' || type === 'contact-lenses-accessories' ? 14 : 10,
  fontWeight: type === 'contact-lenses' || type === 'contact-lenses-accessories' ? 600 : 'normal',
  lineHeight: 1.43,
  whiteSpace: 'normal',
  color: theme.palette.text.primary,
  textAlign: isSearchLayer ? 'center' : 'left',
}))

export const StyledProductBrandName = styled('div', {
  name: 'ProductTile',
  slot: 'ProductBrandName',
  shouldForwardProp: prop => prop !== 'isSearchLayer',
})<{ isSearchLayer?: boolean }>(({ isSearchLayer, theme }) => ({
  lineHeight: 2,
  fontWeight: 600,
  marginTop: theme.spacing(0.5),
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    lineHeight: 1,
    fontSize: 11,
  },
  textAlign: isSearchLayer ? 'center' : 'left',
}))

export const StyledCLProductBrandName = styled('div', {
  name: 'ProductTile',
  slot: 'ProductBrandName',
})(({ theme }) => ({
  lineHeight: 2,
  fontSize: theme.typography.subtitle1.fontSize,
}))

export const StyledProductVariants = styled('div', {
  name: 'ProductTile',
  slot: 'ProductVariants',
  shouldForwardProp: prop => prop !== 'isDisplayed',
})<{ isDisplayed?: boolean }>(({ isDisplayed = true, theme }) => ({
  height: 'fit-content',
  display: isDisplayed ? 'flex' : 'none',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(5),
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(15),
  },
}))

export const StyledLoadingWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'LoadingWrapper',
})(({ theme }) => ({
  flex: 1,
  display: 'flex',
  aspectRatio: '1 / 1',
  backgroundColor: theme.palette.background.light.secondary,
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  position: 'relative',
}))

export const StyledSkeletonWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'SkeletonWrapper',
})({
  flex: 0,
  minHeight: 100,
  width: '100%',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const StyledImageWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'ImageWrapper',
  shouldForwardProp: prop => prop !== 'shouldZoomOnHover',
})<{ shouldZoomOnHover?: boolean }>(({ theme, shouldZoomOnHover }) => ({
  overflow: 'hidden',
  position: 'relative',
  display: 'inline-block',
  fontSize: 0,
  // paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    transition: 'transform .5s',
    '&:hover, &:focus': {
      transform: shouldZoomOnHover ? 'scale(1.1)' : undefined,
    },
  },
}))
